export function dictFilter (val, dictCode) {
  const dictLabel = (dictCode, val) => {
    let dictMap = JSON.parse(localStorage.getItem('rescue_dict_map'));
    if (dictMap && dictCode) {
      if (dictMap && dictMap[dictCode.toUpperCase()]) {
        const dict = dictMap[dictCode.toUpperCase()]
        const filterPro = dict ?
          dict.filter((dict) => {
            return dict.value === val || Number(dict.value) === val
          }) : []
        if (filterPro && filterPro.length) {
          if (filterPro && filterPro.length >= 1) {
            return filterPro[0].label
          } else return val
        }
      }
    }
    return val
  }
  if (val && val instanceof Array) {
    return val.map(v => dictLabel(dictCode, v)).join(',')
  } else {
    return dictLabel(dictCode, val)
  }
}
