<template>
  <div>
    <BaiduMap
      id="container"
      :style="mapStyle"
      :ak="baiduMapKey"
      :map-click="false"
      :center="center"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
      @click="getClickInfo"
      @moving="syncCenterAndZoom"
      @moveend="syncCenterAndZoom"
      @ready="onBaiduMapReady"
      @zoomend="syncCenterAndZoom"
    >
      <BmControl v-if="showControl" :offset="{ width: '10px', height: '10px' }">
        <BmAutoComplete
          v-model="keyword"
          :sug-style="{ zIndex: 999999 }"
          @confirm="handleSelect"
        >
          <van-field
            v-model="keyword"
            error
            required
            label=""
            placeholder="请输入地名关键字"
            style="margin: 10px;display: block;"
          />
        </BmAutoComplete>
      </BmControl>
      <BmView style="width: 100%; height: 100%;" />
      <BmMarker
        :position="{ lng: center.lng, lat: center.lat }"
        :dragging="true"
        animation="BMAP_ANIMATION_BOUNCE"
      />
      <!-- <BmControl
          v-if="showKeyword"
          :offset="{ width: '10px', height: '10px' }"
        >
          <BmAutoComplete v-model="keyword" :sug-style="{ zIndex: 999999 }">
            <HInput v-model="keyword" prefix-icon	="el-icon-search" placeholder="请输入地名关键字" style="margin: 10px;display: block;" />
          </BmAutoComplete>
        </BmControl> -->
      <BmGeolocation
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        show-address-bar
        auto-location
      />
      <BmLocalSearch :keyword="keyword" :auto-viewport="true" :panel="false" />
    </BaiduMap>
  </div>
</template>
<script>
import {
  BaiduMap,
  BmControl,
  BmView,
  BmAutoComplete,
  BmLocalSearch,
  BmMarker,
  BmGeolocation,
} from "vue-baidu-map";
import { mapGeoReverse } from "@/api/common/driver";
export default {
  name: "Map",
  props: {
    showControl: {
      type: Boolean,
      default: false,
    },
    chooseType: {
      type: String,
      default: "click",
    },
  },
  components: {
    BmControl,
    BmAutoComplete,
    BaiduMap,
    BmView,
    BmLocalSearch,
    BmMarker,
    BmGeolocation,
  },
  computed: {
    baiduMapKey() {
      return process.env.VUE_APP_BAIDU_MAP_KEY;
    },
  },
  data() {
    return {
      pointA: null,
      BMap: null, // 地图组件是否就绪
      showMapComponent: true,
      keyword: "", // 地区搜索的信息
      mapStyle: {
        width: "100%",
        height: "230px",
      },
      center: { lng: 116.404, lat: 39.915 },
      choosedLocation: { province: "", city: "", district: "", addr: "" },
      zoom: 15,
    };
  },
  methods: {
    // 搜索
    handleSelect(item) {
      let map = item.currentTarget.map;
      let point = map.re;
      let res = item.item.value;
      if (this.chooseType === "input") {
        // 调整地图中心位置
        this.center.lng = point.lng;
        this.center.lat = point.lat;
        console.log("获取经纬度", point, "获取详细地址", res);
        const province = res.province;
        const city = res.city;
        const district = res.district;
        let addr = res.business;
        this.choosedLocation = {
          province,
          city,
          district,
          addr,
          ...this.center,
        };
        this.$emit("choosedLocation", this.choosedLocation);
      }
    },
    trailPlay() {
      // 创建地图实例
      var map = new this.BMap.Map("container");
      var poiList = [
        new this.BMap.Point(113.33765, 23.150435),
        new this.BMap.Point(113.347655, 23.160435),
        new this.BMap.Point(113.357655, 23.170435),
      ]; // 轨迹点数组
      // 初始化播放变量
      var playIndex = 0;
      var lastPlayIndex = 0;
      var lastPrePlayIndex = 0;
      var playOrPause = true;
      var speed = 1000;
      var timer = null;
      var marker = null;
      let angle_value = null;
      const gpsPlay = () => {
        let route_len = poiList.length;
        // 暂停播放时，清空计时器
        if (!playOrPause) {
          clearTimeout(timer);
          timer = null;
          return;
        }
        // 播放完了路线上的所有点
        if (playIndex > route_len - 1) {
          timer = null;
          return;
        }
        if (route_len > 0 && playIndex == 0) {
          // 初始化状态
          playIndex++;
        }
        timer = timer ? null : timer;
        var gpsData = poiList[playIndex]; // 下一个播放的点
        var gpsLastData = poiList[lastPlayIndex]; // 当前播放的点
        var gpsLastPreData = poiList[lastPrePlayIndex]; // 上一个播放的点
        // 路线上的点去重
        while (
          playIndex < route_len - 1 &&
          gpsLastData.lng == gpsData.lng &&
          gpsLastData.lat == gpsData.lat
        ) {
          lastPlayIndex = playIndex++;
          gpsLastData = poiList[lastPlayIndex];
          gpsData = poiList[playIndex];
        }
        angle_value = calculateAngle([gpsLastPreData, gpsLastData]);
        var mapBounds = map.getBounds();
        var gpsLastPrePoint = new this.BMap.Point(
          gpsLastPreData.lng,
          gpsLastPreData.lat
        );
        var gpsLastPoint = new this.BMap.Point(
          gpsLastData.lng,
          gpsLastData.lat
        );
        if (marker) {
          map.removeOverlay(marker);
        }
        marker = new this.BMap.Marker(gpsLastPoint, {
          rotation: angle_value,
        });

        var polyline = new this.BMap.Polyline([gpsLastPrePoint, gpsLastPoint], {
          strokeColor: "red",
          strokeWeight: 4,
          strokeOpacity: 1,
        });

        // 起点标志添加
        if (lastPrePlayIndex == lastPlayIndex && lastPrePlayIndex == 0) {
          let beginMarker = new this.BMap.Marker(gpsLastPoint, {
            offset: new this.BMap.Size(-1, -20),
          });
          map.addOverlay(beginMarker);
          map.setCenter(gpsLastPoint);
        }
        // 保证路线中运动的点始终在视野范围内
        if (!mapBounds.containsPoint(gpsLastPoint)) {
          map.setCenter(gpsLastPoint);
        }
        map.addOverlay(polyline);
        map.addOverlay(marker);
        lastPrePlayIndex = lastPlayIndex;
        lastPlayIndex = playIndex++;
        // 终点标志添加
        if (playIndex > route_len - 1) {
          let endMarker = new this.BMap.Marker(gpsLastPoint, {
            offset: new this.BMap.Size(-1, -20),
          });
          if (marker) {
            map.removeOverlay(marker);
          }
          map.addOverlay(endMarker);
        }
        timer = setTimeout(gpsPlay, speed);
      };

      const gpsStop = () => {
        if (playOrPause) {
          // 正在播放=>暂停播放
          gpsPlayOrPause();
        }
        map.clearOverlays();
        playIndex = 0;
        lastPlayIndex = 0;
        lastPrePlayIndex = 0;
      };
      const gpsPlayOrPause = () => {
        var btn = document.querySelector(".controller>button");
        if (playOrPause) {
          btn.innerHTML = "播放";
          playOrPause = false;
        } else {
          btn.innerHTML = "暂停";
          playOrPause = true;
          if (playIndex > poiList.length - 1) {
            gpsStop();
          }
          gpsPlay();
        }
      };

      const calculateAngle = (points) => {
        var lastPrePoi = points[0];
        var lastPoi = points[1];
        if (lastPoi.lng == lastPrePoi.lng) {
          if (lastPoi.lat == lastPrePoi.lat) {
            // 横坐标相同、纵坐标相同
            return;
          } else {
            // 横坐标相同、纵坐标不同
            return lastPoi.lat > lastPrePoi.lat ? 0 : 180;
          }
        } else {
          if (lastPoi.lat == lastPrePoi.lat) {
            // 横坐标不同、纵坐标相同
            return lastPoi.lng > lastPrePoi.lng ? 90 : -90;
          } else {
            let first_side_length = lastPoi.lng - lastPrePoi.lng;
            let second_side_length = lastPoi.lat - lastPrePoi.lat;
            let third_side_length = Math.sqrt(
              Math.pow(first_side_length, 2) + Math.pow(second_side_length, 2)
            );
            let cosine_value = first_side_length / third_side_length;
            let radian_value = Math.acos(cosine_value);
            let angle_value = (radian_value * 180) / Math.PI;
            if (angle_value < 90) {
              return second_side_length > 0
                ? 90 - angle_value
                : 90 + angle_value;
            } else {
              return second_side_length > 0
                ? 90 - angle_value
                : angle_value - 270;
            }
          }
        }
      };
      gpsPlay();
    },
    // 通过浏览器定位获得我的经纬度
    getMyPoint(callback) {
      var self = this;
      var geolocation = new this.BMap.Geolocation();
      geolocation.getCurrentPosition(function(res) {
        self.pointA = new self.BMap.Point(res.point.lng, res.point.lat); // 通过浏览器获得我的经纬度
        callback && callback();
      });
    },
    // 测量百度地图两个点间的距离
    getDistance(itemPoint) {
      var map = new this.BMap.Map("");
      var pointB = new this.BMap.Point(
        parseFloat(itemPoint.lng),
        parseFloat(itemPoint.lat)
      ); // 店铺的经纬度
      var distance = (map.getDistance(this.pointA, pointB) / 1000).toFixed(2); // 保留小数点后两位
      return distance;
    },
    drawLine(endSite, startSiteParam = null) {
      let BMap = this.BMap;
      let map = new BMap.Map("container", { enableMapClick: false });
      // map.centerAndZoom(new BMap.Point(this.center.lng, this.center.lat), this.zoom);		// 设置中心点（北京）
      map.clearOverlays(); // 清除地图上的覆盖物

      let startSite = startSiteParam || this.choosedLocation;
      let start = new BMap.Point(startSite.lng, startSite.lat); // 起点
      let end = new BMap.Point(endSite.lng, endSite.lat); // 终点

      let time = "";
      let lenth = "";
      let searchComplete = (results) => {
        if (transit.getStatus() != BMAP_STATUS_SUCCESS) {
          return;
        } // 检测返回状态
        let plan = results.getPlan(0);
        if (!plan) return;
        time = plan.getDuration(true) + "\n"; //获取时间
        lenth = plan.getDistance(true); //获取距离
        console.log("距离：" + lenth);
        console.log("耗时：" + time);
        lenth = lenth.indexOf("米") !== -1 ? "1" : lenth;
        lenth = Math.round(lenth.replace("公里", "")) + "";
        this.$emit("drawLine", { time, lenth });
      };
      let transit = new BMap.DrivingRoute(map, {
        renderOptions: { map: map, autoViewport: true },
        onSearchComplete: searchComplete,
        onPolylinesSet: function() {
          setTimeout(function() {}, 10);
        },
      });
      transit.search(start, end);
    },
    // ready事件：在你需要二次开发或手动控制其子组件，可以使用在此事件中使用返回的 BMap 类和 map 实例进行手动控制
    onBaiduMapReady(e) {
      const that = this;
      this.BMap = e.BMap;
      let BMap = this.BMap;
      navigator.geolocation.getCurrentPosition(function(position) {
        //得到html5定位结果
        var x = position.coords.longitude;
        var y = position.coords.latitude;
        const pointBak = new BMap.Point(x, y);
        const convertor = new BMap.Convertor();
        //由于html5定位的结果是国际标准gps，所以from=1，to=5
        convertor.translate([pointBak], 1, 5, (resPoint) => {
          if (resPoint && resPoint.points && resPoint.points.length > 0) {
            that.center.lng = resPoint.points[0].lng;
            that.center.lat = resPoint.points[0].lat;
          }
          const point = new BMap.Point(that.center.lng, that.center.lat);
          const geo = new BMap.Geocoder();
          geo.getLocation(point, (rs) => {
            const {
              province,
              city,
              district,
              street,
              street_number,
            } = rs.addressComponents;
            that.choosedLocation = {
              province,
              city,
              district,
              addr: street + street_number,
              ...that.center,
            };
            that.$emit("choosedLocation", that.choosedLocation);
          });
        });
      });
    },
    chooseLocation() {
      this.keyword = get(this.form, this.propMap["address"]);
    },
    /** *
     * 地图点击事件。
     */
    getClickInfo(e) {
      // 调整地图中心位置
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
      // 此时已经可以获取到BMap类
      if (this.BMap) {
        const that = this;
        // Geocoder() 类进行地址解析
        // 创建地址解析器的实例
        const geoCoder = new this.BMap.Geocoder();
        // getLocation() 类--利用坐标获取地址的详细信息
        // getPoint() 类--获取位置对应的坐标
        geoCoder.getLocation(e.point, function(res) {
          console.log("获取经纬度", e.point, "获取详细地址", res);
          const addrComponent = res.addressComponents;
          const surroundingPois = res.surroundingPois;
          const province = addrComponent.province;
          const city = addrComponent.city;
          const district = addrComponent.district;
          let addr =
            (addrComponent.street || "") + (addrComponent.streetNumber || "");
          if (!addr && surroundingPois.length > 0 && surroundingPois[0].title) {
            addr += `${surroundingPois[0].title}`;
          } else if (!addr && surroundingPois.length === 0) {
            addr += addrComponent.address || "";
          }
          that.choosedLocation = {
            province,
            city,
            district,
            addr: e.cb && e.cb.z ? e.cb.z.title : addr,
            ...that.center,
          };
          that.$emit("choosedLocation", that.choosedLocation);
        });
      }
    },
    syncCenterAndZoom(e) {
      // 返回地图当前的缩放级别
      this.zoom = e.target.getZoom();
    },
  },
};
</script>
<style lang="scss" scoped></style>
